.day-column {
  margin-bottom: 20px;
  margin: 0 auto;
  border: 1px solid #ccc; 
  background-color: rgba(255, 255, 255, 0.614);
}

.Column {
  flex: 1 1 0;
  width: 100%; /* Mobile default to full width */
}

.class-time {
  font-size:12px;
  font-family: Roboto condensed;
}

.ScheduleContainer {
  
  padding-top: 70px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding-bottom: 50px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.777), white, rgba(0, 0, 0, 0.737));
}

.ScheduleContainer h1 {
  color: rgb(0, 0, 0);
}

.Schedule {
   
  display: flex;
   justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50px;
}

.Schedule p {
  line-height: 1.1;
}

.WeekDay {
  background-color: rgba(0, 0, 0, 0.914);
  border: 1px solid #ccc;
  color: white;
  margin-top: 30px;
  border-radius: 5px;
  text-align: center;
}

.class-name{
  text-transform: uppercase;
}

.timeslot  {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  font-family: Roboto condensed;
  justify-content: center;
 align-items: center;
  background-color: #c44;
  color: white;
  border-radius: 2px;
  border: 1px solid black;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  box-sizing: border-box;
  min-height: 35px;
   
}

/* Mobile-first: Show short names by default */
.WeekDay .short-day-name {
  display: block;
}

.WeekDay .full-day-name {
  display: none;
}

/* Enhancements for larger screens */
@media (min-width: 800px) {
  .Column {
    width: 16%;
  }

  .Schedule {
    flex-direction: row; /* Switch to row layout on wider screens */
    justify-content: space-evenly;
    width:80%
  }

  .WeekDay .short-day-name {
    display: none; /* Hide short names on wide screens */
  }

  .WeekDay .full-day-name {
    display: block; /* Show full names on wide screens */
  }
}
