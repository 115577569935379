 
*{
    margin: 0;
    box-sizing: border-box;
    padding: 0;
  }

  a{
    text-decoration: none; 
    color: inherit;
  }

  .Underline{
    
    text-decoration:underline
  }
 
  .Crimson{
    color: crimson
}

.White{
  color:white
}
.Flexbox{
  display:flex;
  flex-wrap: wrap;
}
  h1{
    font-family: Young Serif;
    font-weight:lighter
  }

  p, h2,h3{
    font-family: Sahitya
  }
  
 
  html {
    scroll-behavior: smooth;
  }
  
.Vertical{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Center{
  text-align: center;
}
 .Green{
  color:green
 }

 button{
  font-family: Roboto;
 }

 .animate {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Visible state: fade in and move to original position */
.animate.visible {
  opacity: 1;
  transform: translateY(0);
}