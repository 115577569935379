/* Pricing.css */

.PricingContainer {
    padding-top: 150px;
    background: linear-gradient(to bottom, black, rgb(92, 92, 92));
    color: white;
    padding-bottom: 100px;
}

.PricingContainer h1 {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 15px;
}

.Intro {
    width: 95%;
    margin: 0 auto;
    font-size: 1.5rem;
}

.BulletPoints {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 100px;
}

.BulletPoints li {
    font-size: 1.5rem;
    margin-top: 90px;
}

.icon {
    font-size: 40px;
    margin-bottom: 10px;
}

.BulletHeader {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: rgb(255, 255, 255);
}

.Description {
    width: 90%;
    color: rgb(255, 255, 255);
    font-size: 0.7em;
    font-style: italic;
    margin: 0 auto;
}

.Memberships {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.MembershipButtons {
    width: 150px;
    margin: 0 auto;
}

.PricingButtonContainer {
    width: fit-content;
    margin: 0 auto;
}

.PricingButtonContainer button {
    width: 300px;
    height: 40px;
    margin: 10px 0;
    font-size: 1.2em;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    font-family: Roboto, sans-serif;
    transition: background-color 0.3s, color 0.3s; /* Added transition for smooth hover effect */
}

.PricingButtonContainer button:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.Vertical {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.Show {
    opacity: 1;
    transform: translateY(0);
}

@media (min-width: 800px) {
    .Description {
        width: 100%;
    }
}

/* Optional: Center class if not defined */
.Center {
    text-align: center;
}
