.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.LoginContainer {
    background-color: lightgrey;
    padding: 5px;
    width: 330px;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative; /* Needed for close button positioning */
}

.LoginContainer h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.LoginContainer .login-options button {
    display: block;
    width: 80%;
    padding: 10px;
    margin: 10px auto;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.LoginContainer .login-google {
    background-color: #db4437;
    color: white;
}

.LoginContainer .login-facebook {
    background-color: #4267B2;
    color: white;
}

.LoginContainer .login-email {
    background-color: #333;
    color: white;
}

.LoginContainer .divider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.LoginContainer .divider {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
}

.LoginContainer .or-text {
    margin: 0 10px;
    color: #666;
    font-size: 20px;
}

.LoginContainer .bottom-message
{
    margin: 0 auto;
    color: #666;
    font-size: 17px;
    white-space: nowrap;

}
/* Close button styling */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    transform: rotate(45deg);
}

.link{
    color: red;
    text-decoration: underline;
    cursor:pointer;
    
}

.rotated-plus {
    transform: rotate(45deg); /* Rotate the X icon to make it look like a + */
    color: #333;
}

