.AboutContainer {
    background-color: rgb(0, 0, 0);
    color: white;
         padding-bottom:70px;
         padding-top:60px;
         border-top: 1px solid white;
}

.Sign{
    font-family: Roboto ;
    font-weight: normal;
}

 

.big{padding-left: 10px;
}
.second {
    margin-top:50px;
}
 
.AboutContainer ul li{
    font-family: Sahitya;
   width:80%;
   min-width: fit-content;
    margin: 0 auto;
    margin-bottom: 20px;
    list-style: none;
    display: flex;
   align-items: center;
   justify-content: flex-start;
   flex-direction: column;
   

}

ul li i {
    color: #e63946; /* Change color */
    margin-top : 10px; /* Add space between icon and text */
    font-size: 3rem; /* Adjust icon size */
    vertical-align: middle; /* Align icon with text */

  }

  .IconWrapper{
    width:100px;
    display:flex;
    justify-content: center;
    align-items: center;
    
  }
.AboutContainer p{
    font-family: Sahitya;

    width:90%;
    margin: 0px auto;
    margin-bottom:20px;
}

.AboutContainer h1{
    text-align: center;
    padding:0;
    padding-top:50px;
    padding-bottom:50px;
    color:white;

}

 


.AboutContainer h3{
    margin-top:20px
}

.Right, .Left {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    text-align: center;
}

.visible {
    opacity: 1;
    transform: translateY(0);
}



@media (min-width: 800px){

.AboutContainer p {
    font-size: 1.5rem;
}

.AboutContainer ul li{
 
    font-size: 1.5rem;
 
}


.AboutTextAndVideo{
    width:90%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.AboutTextAndVideo >*{
    margin: 0 5rem;
    max-width:50%
}

 

 
}