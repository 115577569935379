/* Founders Container */
.FoundersContainer {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

/* Headings */
.FoundersContainer h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.FoundersContainer h2 {
    margin-top: 30px;
    font-size: 1.5em;
    color: #444;
}

/* Paragraph Text */
.FoundersContainer p {
    text-align: center;
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
}

/* Form Styles */
.FoundersForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Form Group */
.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.formGroup label {
    font-size: 1em;
    color: #333;
    font-weight: bold;
}

.formGroup input,
.formGroup select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.formGroup input:focus,
.formGroup select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button Styles */
button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

button:hover {
    background-color: #0056b3;
}

/* CAPTCHA Placeholder */
.captchaPlaceholder {
    width: 100%;
    padding: 10px;
    background-color: #e9ecef;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.9em;
    color: #777;
}

/* Family Member Form */
.familyMemberForm {
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.familyMemberForm h3 {
    margin-top: 0;
    color: #555;
}

/* Submit Button */
.submitButton {
    align-self: center;
    width: 100%;
    max-width: 200px;
    background-color: #28a745;
}

.submitButton:hover {
    background-color: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
    .FoundersContainer {
        padding: 15px;
    }

    .FoundersContainer h1, .FoundersContainer h2 {
        font-size: 1.5em;
    }

    button {
        width: 100%;
    }
}
