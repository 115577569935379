.FAQContainer {
  margin: auto;
 
  background: linear-gradient(to bottom, rgb(255, 255, 255) , rgb(0, 0, 0)),
              linear-gradient(to right, rgb(255, 255, 255)  , rgb(0, 0, 0));
  padding: 100px 0;
  border-top: 2px solid black;
}

 

  .FAQ{
    width:fit-content;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
   
  
  .FAQFlexbox{
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 2px 5px black;
    
  }

  .FAQFlexbox h1{
    text-align: center;
  }

 

 .Second{
  margin-left:20px;
 }

 .Third{
 margin-left:40px;
 }
  .faq-controls{
 
    margin: 20px 50px;
    width:100%;
 
  }
  
  .faq-controls >*{
    margin: 5px auto;
    height:30px;
    width:200px;
    display: block;
    background-color: rgba(0, 0, 0, 0.709);
    color: white;
    border: 2px white solid;
    font-size: 15px;
    font-weight: bold;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.84);
     transition: background-color 0.3s ease;
  }
  
  .faq-controls >*:hover{
    cursor: pointer;
    background-color: rgb(90, 83, 83);
  }
.VideoContainer{
 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top:30px;
}

  @media(min-width: 800px){
    .FAQ-and-video{
      display:flex;
      margin-top: 50px;
      justify-content: space-around;
      align-items: flex-start;
    }
    .FAQ-and-video >*{
      width:50vw; 
    }
    
    .faq-controls >*{
      width:400px
    }

  }
 
  
 