.LandingContainer {
    
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
 
}

.video-background {
    background: url('../Media/gracie.webp') no-repeat center center;
    background-size: cover;
    z-index: 500;
  }

.NameAndLeaf{
  
 
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 3px 3px 5px black;
    position:relative;
}
 

 
.Center{
    margin: 0 auto;
  }
.LandingContainer h2, h3{
   text-align: center;
   font-family: Young Serif;
}

.MoveDown h3{
    font-size: 15px;
    font-weight: normal;
  }

.MoveDown{
    margin: 10px 0;
    margin-bottom:50px
}

.LandingContainer img{
    height: 70px;
}

.ButtonContainer >*{
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.653);
}

.logo {
   
    opacity: 0;
    transition: opacity 2s ease-out;
}

.show{
    transform: translateY(0);
    opacity: 1;
  
}

.LandingContainer h1 {
    color: white;
 
}

.Crimson {
    color: #DC143C;
}

.LandingContainer button{
    margin: 5px;
}



.ButtonContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
}
 

.Disclaimer{
    font-size: 20px;
text-align: left;
margin: 0 auto;
width: fit-content;
font-family: Sahitya;
}

 
.Disclaimer li {
    list-style-type: none; /* Disable default bullets */
    position: relative;
    padding-left: 30px; /* Add space for custom bullet */
  
}

 

/* Get Started Button */
.GetStartedButton {
    
    background-color: #bd0c09; /* Soft pink */
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    height:70px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 250px;
    text-align: center;
    border: 1px solid #ffffff; /* White border */
}

.GetStartedButton:hover {
    background-color: #f9b7b6; /* Slightly darker pink on hover */
}

.video-container {
    background: url('../Media/landing.png') no-repeat center center;
    background-size: cover;
    position: absolute;
    width: 100%; /* Set your preferred width */
    height: 100%; /* Set your preferred height */
    overflow: hidden; /* Hide any overflow */
    z-index:-6
    
  }
  
  .video-container video {
    height:100%;
    width:100%;
   
    object-fit: cover;
    object-position: top;
    z-index:-5;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.429); /* Dark overlay, adjust the alpha for transparency */
    pointer-events: none; /* Allow interactions to pass through */
    z-index:0;
  }
  
  

/* View Membership Options Button */
.ViewPlansButton {
    height:70px;
    background-color:rgb(223, 1, 1);
    color: white;
    border: 1px solid #ffffff; /* White border */
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 1.9rem;
    font-weight: normal;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 250px;
    text-align: center;
    display:flex;
   justify-content: center;
   align-items: center;
   font-family: roboto condensed;;
}

.ViewPlansButton:hover {
    background-color: white;
    color: black;
}

.NameAndLeaf img{
    height:350px
      }


@media(min-width:800px){

    .NameAndLeaf img{
        height:350px
          }
    

    .video-container {
        background: url('../Media/landing2.png') center center;
        background-size: cover; /* Ensures the background covers the entire container */
        background-repeat: no-repeat; /* Prevents the image from repeating */
        width: 100%; /* Ensures the container takes up full width */
        height: 100vh; /* Ensures the container takes up the full height of the viewport */
      }
    
    .LandingContainer {
      
       justify-content: center;}
 

    .ButtonContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
   
    }

    
.NameAndLeaf{
 
  font-size: 25px;
  
  color:crimson;


}


     
    
}
 