.Jocko iframe {
   border-radius:20px;
   margin: 0px auto;
   box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.85);
    width:350px;
    height:230px;
    position:relative;
    right:0;
    overflow: hidden; /* Ensures that content inside the iframe does not overflow the rounded corners */
}

@media(min-width: 800px){
    .Jocko iframe {
         width:550px;
         height:400px;
          
     }
}