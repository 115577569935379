.purchase-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.purchase-form-container h2{
  margin-top:10px;
}

.purchase-form h2 {
  color: #333;
  margin-bottom: 20px;
}

.grid-form {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-gap: 20px; /* Gap between elements */
}

/* Make the button span across both columns */
.submit-btn {
   display:block;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 50px auto;
  width: 50%; 
  text-align: center; 
  position: relative;
 
}

.submit-btn:hover {
  background-color: #555;
}

/* Ensure form fields are full width */
.form-group {
  display: flex;
  flex-direction: column;
}
.field-flex{
  display: flex;
  justify-content: space-between;
}
.half{
  width:48%;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.form-group input, .form-group select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

/* Responsive design for smaller screens */
 
