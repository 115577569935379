.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  color: white;
  padding: 10px;
}

.faq-question h2 {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: 2px;
  padding-left: 5px;
}

.faq-item-container {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin: 2px auto;
}

.faq-item {
  width:100%
}

.faq-item-container button {
  height: 35px;
  width: 35px;
  background-color: #05050500;
  border: none;
  font-size: 20px;
  color: white;
}

.faq-item-container button:hover {
  cursor: pointer;
}

/* Container for the answer content */
.faq-answer-wrapper {
  overflow: hidden; /* Prevents overflow during transitions */
}

.faq-answer {
  width:98%;
  margin:0 auto;
  font-size: 1.2em;
  padding: 15px;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.741));
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 0.5px solid rgb(255, 255, 255);
  border-left: 0.5px solid rgb(255, 255, 255);
  border-bottom: 0.5px solid rgb(255, 255, 255);
  transition: opacity 0.5s ease; /* Optional: fade in/out effect */
}
