.CoachContainer h3{
    margin:20px auto;
    font-family: Young Serif
}

.CoachContainer h2{
    margin:20px auto;
    font-family: Young Serif;

  
}

.CoachesContainer h2{
    margin:20px auto;
    font-family:  Sahitya;
    font-size: 20px;
}

.Coach:hover{
    cursor:pointer
}

.Coach{
    position:relative;
    font-family: Roboto;
    width:300px;
    margin: 0 auto;
    
}
.Profile{
    max-width:300px;
    position: absolute;
    top:0;
    padding: 10px 10px;
    margin: 0 auto;
   
}
.Profile ul{
    
    padding: 30px 30px;
}


.Name{
    padding: 0 20px;
    font-size: 1.8em;
    padding-top:20px;
    font-weight:lighter;
    text-shadow: 2px 2px 2px black;
    transform: translateY(-30px);
    transition: transform 0.5s ease-in;
 
}

.CoachDescription{
    font-size:1.1em;
    padding: 0 20px;
    font-weight:lighter;
    transform: translateY(-30px);
    transition: transform 0.5s ease-in;
}
.AccomplishmentsList{
    font-size: 1em;
    font-weight:lighter;
    transform: translateY(30px);
    transition: transform 0.5s ease-in;
}

.Slide{
    transform:translateY(0)
}

 
.CoachNameAndBio{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Dim{
    filter: brightness(25%);
}

.CoachContainer{
    margin-top: 50px;
    margin-bottom: 70px;
    
}

.Coach h4{
    color: white;
    font-family:  Sahitya;
    font-weight: normal;
    font-size: 25px;
    font-style: italic;
}

.Coach img{
    border-radius: 10px;
    width: 300px; /* Set your desired width */
        height: 400px; /* Set your desired height */
    object-fit: cover; /* Ensures the image maintains its aspect ratio and fills the area */
    display: block;
    margin: 30px auto;
    border: 2px inset white;
    box-shadow: 10px 10px 10px black;

}

@media(min-width:800px){
 

    .Coach img{
        width: 300px; /* Set your desired width */
        height: 400px; /* Set your desired height */
        border-radius: 10px;
        border:2px solid rgb(255, 255, 255);
    }

    .Profile{
        max-width:300px;
 
         
    }
}