.CoachesContainer{
    border-top: 4px solid black;
    border-bottom: 2px solid black;
    background-color: rgba(255, 255, 255, 0.292);
    color: white;
    background: linear-gradient(to bottom, black , rgba(255, 255, 255, 0.312)),
                linear-gradient(to right, black  , rgba(126, 126, 126, 0.35));
    padding-bottom: 50px;
    padding-top:50px;
}
.CoachesContainer h1 {
   
    margin: 40px auto;
    margin-top:70px;
}

.CoachesContainer h2 {
    text-align: left;
    margin: 0 auto;
    margin-bottom:80px;
    font-family: Young Serif;
    font-weight: normal;
    width: 90%;
}

.CoachesContainer h4 {
    text-align: center;
    color: black;
    margin:10px auto;
}

.CoachesIntro {
    width: 90%;
    margin: 0 auto;
}

@media(min-width:800px){
    .Coaches{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}