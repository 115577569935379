.NavbarContainer{
     
     top:0;
    z-index: 50;
     width:100vw;
    opacity: 1;
    position:fixed;
    color:rgb(255, 255, 255);
  
}

 

 
.LoginText{
    padding-left: 5px;
}

 
 
.NavbarList{ 
    max-width:100vw;
    position: absolute;
    width: 100vw;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    font-family: Roboto condensed;
     
    font-size: 20px;
    background-color: rgb(18, 18, 18);
    transform: translateX(-200%);
    transition: transform 0.5s ease-in-out;
    z-index:3;
    will-change: transform;
    border-bottom:2px solid white;
    height:100vh
}

.NavbarGetStartedButton {
    
    background-color: #bd0c09; /* Soft pink */
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    height:70px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 250px;
    text-align: center;
    border: 1px solid #ffffff; /* White border */
}

.NavbarGetStartedButton:hover {
    background-color: #f9b7b6; /* Slightly darker pink on hover */
}
.NavbarViewPlansButton {
    height:70px;
    background-color: rgb(223, 1, 1);
    color: white;
    border: 1px solid #ffffff; /* White border */
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 1.9rem;
    
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 250px;
    text-align: center;
    display:flex;
   justify-content: center;
   align-items: center;
   font-family: Roboto condensed;
  
   font-weight: ligther;
   
   margin: 0 auto;
}

.NavbarViewPlansButton:hover {
    background-color: white;
    color: black;
}

.NavbarList >*{
    margin-bottom: 20px auto;
} 

.HamburgerMenu{
    font-size: 30px;
    margin:20px;
    color:white;
    position: fixed;
    right: 20px
}
 

.NavbarList.show{ 
 transform: translateX(0%);
 
}

#logo-full img{
    height:200px
 }

 

 .LogoAndHamburger{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(18, 18, 18);
    z-index:50;
    border-bottom: 2px solid white;
  height:50px;
 
 }

 .navbar-logo{
    transform: translateY(-100%);
    transition: transform .1s ease-in;
    opacity:0.5
 }

.expand{
    height:100px;
    transform: translateY(0);
}

.NavbarContainer img {
    height: 100px;
   
    display:block;
    margin: 0 auto;
     
   
}

.ViewPlansButton{
    margin: 0 auto;
}

.LanguageSelection{
display: flex;
align-items: center;
justify-content: flex-end;
font-size: 17px;
 
}
 
.LanguageSelection p{
 
    width: fit-content; 
    margin:5px 20px;
    
}
.NavbarList li{
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease-in;
    color:rgba(255, 255, 255, 0.835);
    font-weight:normal;
    margin-bottom:25px;
  
}

.NavbarList li:hover{
    cursor: pointer;
    transform: scale(1.2);
}

.NavbarList button{
    display:block;
    margin: 0 auto;
    margin-bottom:20px;
    border-radius: 10px;
}

.PhoneIcon, .IgIcon{
    color: rgba(255, 255, 255, 0.839);
    display: block;
    text-align: center;
 font-weight: normal;
padding: 15px 25px;
font-family: Roboto condensed;
font-size: 20px;
}

.NavbarIcons{
    display: flex;
    justify-content: center;
}

.number{
    margin-left:7px;
}
.IgIcon{
    font-size: 25px;
}

.NavbarButtons a {
 height:100%;
    display:block;

 }

 .opaque{
    opacity: 1;
 }

@media(min-width:900px){
    .HamburgerMenu{
        display: none;
    }

#logo-bottom{
    display:none
}

#logo-full{
    display:none
}

    .NavbarButtons a {
        padding-top: 0px;
           
        }

    .NavbarList li{
        
        margin-bottom:0px;
      
    }

    .PhoneIcon, .IgIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
    }

 
    .NavbarList{
        
        display: flex;
         justify-content:flex-end;
        height:100%;
        transform: translateY(0%);
        max-width: 100%;
        font-size: 20px;
        padding-right:100px;
        color:rgb(255, 255, 255);
      
        align-items: flex-end;
        padding-bottom: 5px;
       
    }  
    .NavbarList >*{
        margin: 0 15px
    } 

    .Logo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height:100%;
        
    }

    .NavbarContainer{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
    }

    
    .NavbarContainer img {
   padding-left:100px;
    }

    .NavbarButtons {
        width:40%;
        display: flex;
        position:fixed;
        justify-content: flex-end;
        align-items: center;
        top:15px;
        height:40%
    }



    .NavbarButtons button {
       height: 100%;
       width:200px;
       display: flex;
       justify-content: center;
       align-items: center;
        font-weight: normal;
       margin: 0 5px;
       display: block;
    }

    .NavbarViewPlansButton {
        height: 100%;
        width:200px;
        display: flex;
        justify-content: center;
        align-items: center;
         font-weight: lighter;
        margin: 0 5px;
     }

   
     .NavbarViewPlansButton{
        display: none;
    }

    
 
}