.FooterContainer{
    background-color: black;
    color:white;
}
.FooterFlexbox{
    border-top:  double rgb(255, 255, 255) 2px;
    color:white;
    background-color: black;
    font-size:20px;
    padding: 10px 10px;
  
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.FooterContainer span{
    text-decoration: underline;
    font-style: italic;
}

.FooterContainer ul{
   list-style: none;
   font-family: Roboto;
   font-weight: lighter;
}

.FooterContainer li:hover{
    cursor: pointer;
    text-decoration: underline;
 }

.FooterContainer span:hover{
    cursor: pointer;
}

.FooterContainer img{
    height:100px
}

.FooterContainer h5{
    padding: 10px 0;
    text-align: center;
    font-weight: 200;

}